import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { authFetch, useValidateUser } from './AuthProvider';

const useStyles = makeStyles((theme) => ({
  table: {
    // minWidth: 650,
  },
  colInfo: {
      minWidth: '10rem',
  },
  colName: {
    // minWidth: '20rem',
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function HistoryList(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [loaded, setLoaded] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [rows, setRows] = useState([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const validUser = useValidateUser('ADMIN AUTHOR');

  const handleItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const loadRows = () => {
      authFetch(process.env.REACT_APP_API_URL + '/stories/updates/2000-01-01', {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json;charset=utf-8'
        }
      })
        .then(r => r.json())
        .then(r => {
          if ('success' === r.status) {
              setRows(r.message)
              setLoaded(true)
          } else {
              console.error("Failed to load history", r)
          }
        })
        .catch(err => {
        console.error("Failed to load hisory", err)
      })
  }

  const handleDeleteRow = (rowId) => {
    authFetch(process.env.REACT_APP_API_URL + '/stories/updates/' + rowId, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    })
      .then(r => {
        if (true === r.ok) {
          loadRows();
        }
        else {
          console.error("Failed to delete history row", r)
        }
      })
      .catch(err => {
        console.error("Failed to delete history row", err)
    })
  }

  useEffect(() => {
    setLoaded(false)

    if (props.open) {
      loadRows();
    }
  }, [props.open]);

  return (
    <Dialog fullScreen={fullScreen} maxWidth='xl' onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open && loaded}>
      <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
        История изменений
      </DialogTitle>
      <DialogContent dividers>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table" size="small">
              <TableBody>
              {rows.map((row) => (
                  <TableRow key={row.id}>
                      <TableCell align="left">{row.change_date}</TableCell>
                      <TableCell align="left">{row.name} ({row.description})</TableCell>
                      {validUser &&
                        (<TableCell align="center">
                          <IconButton size="small" onClick={() => handleDeleteRow(row.id)}>
                            <DeleteIcon fontSize="small"/>
                          </IconButton>
                        </TableCell>)
                      }
                  </TableRow>
              ))}
              </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.handleClose} color="primary">
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
}