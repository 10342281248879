import {
    types,
    flow,
  } from "mobx-state-tree";
  import { authFetch } from '../components/AuthProvider';
  import { pageCut } from './PageCut';
  import { commentPerPage } from '../utils/constants';
  
  export const Comment = types
    .model("Comment", {
      id: types.identifierNumber,
      id_parent: types.optional(types.number, 0),
      id_story: types.number,
      id_user: types.number,
      user_name: types.string,
      is_author: types.optional(types.number, 0),
      text: types.optional(types.string, ""),
      date_create: types.optional(types.string, ""),
      date_update: types.optional(types.string, ""),
      has_children: types.optional(types.number, 0),
      children: types.array(types.late(() => Comment)),
      childrenLoaded: types.optional(types.boolean, false),
    })
    .actions(self => {
      function addComment(comment) {
        self.children.push(comment)
      }

      function updateComments(json) {
        self.children.clear()

        json.forEach(commentJson => {
          self.children.push(commentJson)
        })

        self.children.forEach(comment => {
          if (comment.has_children) {
            comment.addComment({id: Math.random(), id_parent: comment.id, id_story: comment.id_story, id_user: Math.random(), user_name: 'unknown', text: 'Загрузка...'})
          }
        })

        self.has_children = 1
        self.childrenLoaded = true
      }

      const loadChildComments = flow(function* loadChildComments() {
        try {
          const json = yield fetch(
                            process.env.REACT_APP_API_URL + '/stories/' + self.id_story + '/comments/children/' + self.id, {
                            method: 'GET',
                            headers: {
                              'Content-Type': 'application/json;charset=utf-8'
                            }
                        }
                      ).then(response => response.json()) 
            updateComments(json.message)
        } catch (err) {
            console.error("Failed to load comments ", err)
        }
      })

      const addChildComment = flow(function* addChildComment(comment) {
        try {
          const json = yield authFetch(process.env.REACT_APP_API_URL + '/stories/' + self.id_story + '/comments', {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json;charset=utf-8'
                            },
                            body: JSON.stringify(comment)
                          }
                        ).then(response => response.json())
            if ('success' === json.status) {
              if (self.childrenLoaded === true) {
                self.children.unshift(json.message)
              } else {
                loadChildComments()
              }
            } else {
              console.error("Failed to save comment", json)
            }
        } catch (err) {
          console.error("Failed to save comments ", err)
        }
      })

      // function addChildComment(comment) {
      //   authFetch(process.env.REACT_APP_API_URL + '/stories/' + self.id_story + '/comments', {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json;charset=utf-8'
      //     },
      //     body: JSON.stringify(comment)
      //   })
      //     .then(r => r.json())
      //     .then(r => {
      //       if ('success' === r.status) {
      //         // self.children.unshift(r.message)
      //         addComment(r.message)
      //       } else {
      //         console.error("Failed to save comment", r)
      //       }
      //     })
      //     .catch(err => {
      //       console.error("Failed to save comment", err)
      //   })
      // }

      return {
        addComment,
        loadChildComments,
        addChildComment
      }
    })

  export const MarkNote = types
    .model("MarkNote", {
      id: types.identifierNumber,
      id_story: types.number,
      id_mark: types.string,
      id_user: types.number,
      user_name: types.string,
      text: types.optional(types.string, ""),
      date_create: types.optional(types.string, ""),
      date_update: types.optional(types.string, ""),
    })

  export const Tooltip = types
    .model("Tooltip", {
      id: types.identifierNumber,
      id_story: types.number,
      id_tooltip: types.string,
      text: types.optional(types.string, ""),
      date_create: types.optional(types.string, ""),
      date_update: types.optional(types.string, ""),
    })

  export const Story = types
    .model("Story", {
      id: types.identifierNumber,
      id_parent: types.optional(types.number, 0),
      name: types.optional(types.string, ""),
      annotation: types.optional(types.string, ""),
      text: types.optional(types.string, ""),
      page_count: types.optional(types.number, 0),
      date_create: types.optional(types.string, ""),
      date_update: types.optional(types.string, ""),
      has_children: types.optional(types.number, 0),
      children: types.array(types.late(() => Story)),
      childrenLoaded: types.optional(types.boolean, false),
      comment_count: types.optional(types.number, 0),
      comment_pages: types.optional(types.number, 0),
      comment_current_page: types.optional(types.number, 0),
      comments: types.array(Comment),
      marked: types.optional(types.number, 0),
      markNotes: types.array(MarkNote),
      tooltips: types.array(Tooltip),
      edit_status: types.optional(types.number, 0),
      edit_status_text: types.optional(types.string, ""),
      edit_now: types.optional(types.number, 0),
      draft: types.optional(types.number, 0),
      need_donat: types.optional(types.number, 0),
      public_id: types.optional(types.string, ""),
    })
    .actions(self => {
      function addStory(story) {
        self.children.push(story);
      }

      function updateText(newText) {
        self.text = newText
      }

      function updateChildren(json) {
        self.children.clear()

        json.forEach(storyJson => {
          self.children.push(storyJson)
        })

        self.children.forEach(story => {
          if (story.has_children) {
            story.addStory({id: Math.random(), name: 'Загрузка...'})
          }
        })

        self.childrenLoaded = true;
      }

      const loadChildren = flow(function* loadChildren() {
          try {
            const json = yield fetch(
                              process.env.REACT_APP_API_URL + `/stories/children/` + self.id, {
                              method: 'GET',
                              headers: {
                                'Content-Type': 'application/json;charset=utf-8'
                              }
                          }
                        ).then(response => response.json()) 
              updateChildren(json.message)
          } catch (err) {
              console.error("Failed to load books ", err)
          }
      })

      return {
        addStory,
        updateChildren,
        loadChildren,
        updateText
      }
    })

    export const CurrentReadingStory = types
    .model("CurrentReadingStory", {
      id: types.optional(types.number, 0),
      text: types.optional(types.string, ""),
      empty: types.optional(types.boolean, false),
      notes: types.array(MarkNote),
      tooltips: types.array(Tooltip),
    })
    .actions(self => {
      function addNote(note) {
        self.notes.push(note);
      }

      function updateText(text) {
        self.text = text
      }

      function getTooltip(id) {
        let text = '';
        self.tooltips.forEach((tooltip) => {
          if (tooltip.id_tooltip === id) {
            text = tooltip.text
          }
        })
        return text
      }

      return {
        addNote,
        updateText,
        getTooltip,
      }
    })

    export const Book = types
    .model("Book", {
      state: types.enumeration("State", ["init", "pending", "done", "error"]),
      stories: types.array(Story),
      expandingStory: types.optional(types.reference(Story), 0),
      selectedStory: types.optional(types.reference(Story), 0),
      readerLink: types.optional(types.string, ""),
      sharedLink: types.optional(types.string, ""),
      currentReadingStory: CurrentReadingStory,
      initText: types.optional(types.string, ""),
      commentAnswering: types.optional(types.number, 0),
    })
    .actions(self => {
      function selectStory(nodeId) {
        self.selectedStory = nodeId;
        self.loadComments(nodeId, 1);
      }

      function setReaderLink(link) {
        self.readerLink = link;
      }

      function setSharedLink(link) {
        self.sharedLink = link;
      }

      function setInitText(text) {
        self.initText = text
      }

      function compareText(text) {
        if (self.initText == text) {
          console.log('равны')
        } else {
          console.log('не равны')
        }

      }

      function loadChildren(nodeId, forceLoad) {
        self.expandingStory = nodeId;
        if (self.expandingStory.childrenLoaded === false || forceLoad === true) {
          self.expandingStory.loadChildren();
        }
      }

      function updateStories(json) {
        self.stories.clear()

        json.forEach(storyJson => {
          self.stories.push(storyJson)
        })

        self.stories.forEach(story => {
          if (story.has_children) {
            story.addStory({id: Math.random(), name: 'Загрузка...'})
          }
        })
      }

      function setState(newState) {
        self.state = newState
      }

      function setCurrentReadingText(text) {
        self.currentReading.updateText(text)
      }

      // function setCurrentReadingEmpty(empty) {
      //   self.currentReadingEmpty = empty
      // }

      function setCurrentReadingStory(story) {
        self.currentReadingStory = story
      }

      const loadStories = flow(function* loadStories() {
        if (self.state !== "pending") {  
          self.state = "pending"
          try {
            const json = yield fetch(
                              process.env.REACT_APP_API_URL + `/stories/children/`, {
                              method: 'GET',
                              headers: {
                                'Content-Type': 'application/json;charset=utf-8'
                              }
                          }
                        ).then(response => response.json()) 
              updateStories(json.message)
              self.state = "done"
              self.selectedStory = self.stories[0].id;
              loadChildren(self.stories[0].id, false);
          } catch (err) {
              console.error("Failed to load books ", err)
              self.state = "error"
          }
        }
      })

      function currentReadingAddNote(note) {
        note.id_story = self.currentReadingStory.id
        authFetch(process.env.REACT_APP_API_URL + '/stories/' + note.id_story + '/mark-notes', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify(note)
        })
          .then(r => r.json())
          .then(r => {
            if ('success' === r.status) {
              note.id = r.message
              self.currentReadingStory.addNote(note)
            } else {
              console.error("Failed to save note", r)
            }
          })
          .catch(err => {
            console.error("Failed to save note", err)
        })
      }

      function clearCurrentReading()
      {
        const story = { 
          id: 0,
          text: '',
          empty: true,
          notes: [],
        }
        setCurrentReadingStory(story)
      }

      function updateCurrentReading(json, isPublic) {
        let story = { 
          id: json.message.id
        }
        if ('success' === json.status) {
          story.notes = json.message.mark_notes
          story.tooltips = json.message.tooltips
          if (json.message.text === "") {
            story.empty = true
            story.text = '<h2>' + json.message.name + '</h2><p>Текст этой истории пока не загружен. Следите за обновлениями...</p>'
            setCurrentReadingStory(story)
          } else {
            console.log(json.message);
            story.empty = false
            let setCRS = self.setCurrentReadingStory.bind(self);
            if (!json.message.text.trim().startsWith('<h')) {
              setInitText(json.message.text);
              pageCut(json.message.name, json.message.text, story, isPublic, json.message.need_donat, setCRS)
            } else {
              const startHeader = json.message.text.indexOf('>') + 1;
              const endHeader = json.message.text.indexOf('</h');
              const startText = json.message.text.indexOf('<', endHeader + 1);
              let header = json.message.text.slice(startHeader, endHeader);
              setInitText(json.message.text.slice(startText));
              pageCut(header, json.message.text.slice(startText), story, isPublic, json.message.need_donat, setCRS)
            }
          }
        } else {
          story.empty = true
          story.text = '<h2>Ошибка.</h2><p>Такой истории не существует или она находится в статусе "черновик".</p>'
          setCurrentReadingStory(story)

          console.error("Failed to load current reading story", json)
        }
      }

      const loadCurrentReading = flow(function* loadCurrentReading(storyId, publicStory) {
        const url = publicStory === true ? process.env.REACT_APP_API_URL + '/stories/public/' + storyId :
          process.env.REACT_APP_API_URL + '/stories/' + storyId + '/true'
        
        try {
          const json = yield authFetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8'
            }
          })
          .then(r => r.json())
          updateCurrentReading(json, publicStory)
        }
        catch (err) {
          let story = { 
            id: Number(storyId),
            empty: true,
            text: '<h2>Ошибка.</h2><p>Такой истории не существует или она находится в статусе "черновик".</p>'
          }
          setCurrentReadingStory(story)

          console.error("Failed to load current reading story", err)
        }
      })

      function updateComments(json) {
        self.selectedStory.comment_pages = json.pagination.totalPages
        self.selectedStory.comment_current_page = json.pagination.currentPage
        self.selectedStory.comment_count = json.pagination.totalRows
        self.selectedStory.comments.clear()

        json.data.forEach(commentJson => {
          self.selectedStory.comments.push(commentJson)
        })

        self.selectedStory.comments.forEach(comment => {
          if (comment.has_children) {
            comment.addComment({id: Math.random(), id_parent: comment.id, id_story: comment.id_story, id_user: Math.random(), user_name: 'unknown', text: 'Загрузка...'})
          }
        })
      }

      const loadComments = flow(function* loadComments(storyId, page) {
        try {
          const json = yield fetch(
                            process.env.REACT_APP_API_URL + '/stories/' + storyId + '/comments?page=' + page + '&perPage=' + commentPerPage, {
                            method: 'GET',
                            headers: {
                              'Content-Type': 'application/json;charset=utf-8'
                            }
                        }
                      ).then(response => response.json()) 
            updateComments(json.message)
        } catch (err) {
            console.error("Failed to load comments ", err)
        }
      })

      function selectedStoryAddComment(comment) {
        authFetch(process.env.REACT_APP_API_URL + '/stories/' + self.selectedStory.id + '/comments', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify(comment)
        })
          .then(r => r.json())
          .then(r => {
            if ('success' === r.status) {
              self.loadComments(self.selectedStory.id, 1)
            } else {
              console.error("Failed to save comment", r)
            }
          })
          .catch(err => {
            console.error("Failed to save comment", err)
        })
      }

      function setCommentAnswering(commentAnswering) {
        self.commentAnswering = commentAnswering
      }

      return {
          selectStory,
          setReaderLink,
          setSharedLink,
          loadChildren,
          loadStories,
          loadComments,
          selectedStoryAddComment,
          setCommentAnswering,
          setState,
          // setCurrentReadingEmpty,
          setCurrentReadingText,
          setCurrentReadingStory,
          loadCurrentReading,
          currentReadingAddNote,
          clearCurrentReading,
          setInitText,
          compareText,
      }
    })